<template>
  <div class="plugForm">
    <vPlugSelect
      :selected-elements="selectedElements"
      :active-dropdown="activeDropdown"
      @onInputSearchElement="onInputSearchElement"
      @selectElement="selectElement"
      @selectByFilter="selectByFilter"
      @resetSelectedElements="resetSelectedElements"
      @onCheckSelectedData="onCheckSelectedData"
      @toggleActiveDropdown="toggleActiveDropdown"
    />
    <vSelect
      key="type"
      :values="blockTypes"
      :data="blockTypesSelected"
      :outside-click-data="outsideClickData"
      element="type"
      placeholder="Как заглушить?"
      @selectValue="selectBlockType"
    />
    <vButton
      :disabled="!changeData.value"
      @click="save"
    >
      Сохранить
    </vButton>
    <vPlugFormInfo />
    <vLightPreloader
      v-show="lightPreloader"
      :fixed="true"
      :transparent="true"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vLightPreloader from '@/components/v-light-preloader'
import vPlugFormInfo from '@/components/profile/plugForm/v-plug-form-info.vue'
import vPlugSelect from '@/components/profile/plugForm/v-plug-select.vue'
import vButton from '@/components/v-button'
import vSelect from '@/components/v-select.vue'
export default {
  name: 'PlugForm',
  components: {
    vButton,
    vPlugFormInfo,
    vPlugSelect,
    vLightPreloader,
    vSelect,
  },
  inject: ['selectedElementsId', 'changeData', 'restaurants'],
  props: {
    selectedElements: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeDropdown: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedElementsIdArray: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: [
    'onInputSearchElement',
    'selectElement',
    'selectByFilter',
    'resetSelectedElements',
    'onCheckSelectedData',
    'toggleActiveDropdown',
    'resetChangeDataFlag',
    'togglePlugPreloader',
    'onChangeData',
  ],
  data() {
    return {
      lightPreloader: false,
      blockTypesSelected: '',
      outsideClickData: null,
      blockTypes: [
        { id: 0, name: 'Только оплату СБП' },
        { id: 1, name: 'Только оплату' },
        { id: 2, name: 'Сервис полностью' },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  mounted() {
    document.addEventListener('click', this.clickUotSide)
    const stubType = this.restaurants.value.filter((i) => i.stub_type !== null)
    this.blockTypesSelected =
      stubType[0] && stubType[0].stub_type === 'PAYMENT'
        ? 'Только оплату'
        : stubType[0] && stubType[0].stub_type === 'ALL'
        ? 'Сервис полностью'
        : 'Только оплату СБП'
  },
  unmounted() {
    document.removeEventListener('click', this.clickUotSide)
  },
  methods: {
    ...mapActions('plug', ['SAVE_PLUG']),
    clickUotSide(e) {
      this.outsideClickData = e.composedPath()
    },
    selectBlockType(value) {
      this.$emit('onChangeData')
      this.blockTypesSelected = value.name
    },
    async save() {
      this.$emit('togglePlugPreloader')
      await this.SAVE_PLUG({
        token: this.GET_AUTHENTIFICATED,
        type:
          this.blockTypesSelected === 'Сервис полностью'
            ? 'ALL'
            : this.blockTypesSelected === 'Только оплату'
            ? 'PAYMENT'
            : 'SBP',
        ids: this.selectedElementsIdArray,
      })
      this.$emit('resetChangeDataFlag')
      setTimeout(() => {
        this.$emit('togglePlugPreloader')
      }, 500)
    },
    onInputSearchElement(val) {
      this.$emit('onInputSearchElement', val)
    },
    selectElement(id) {
      this.$emit('selectElement', id)
    },
    selectByFilter(type) {
      this.$emit('selectByFilter', type)
    },
    resetSelectedElements() {
      this.$emit('resetSelectedElements')
    },
    onCheckSelectedData(val) {
      this.$emit('onCheckSelectedData', val)
    },
    toggleActiveDropdown(val) {
      this.$emit('toggleActiveDropdown', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.plugForm {
  width: 100%;
  max-width: 636px;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  background: #ffffff;
  padding: 24px 24px 8px 24px;
  position: relative;
}
</style>
